<template>
    <div class="profile-edit" v-if="user">
        <div class="container" style="max-width:1000px;margin:auto">
            <edit :fs="fs" :id="user._id" api="users" title="profile"></edit>
        </div>
    </div>
</template>

<script>
import Edit from "@/components/Edit";
import Submit from "@/components/Submit";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { states } from "@/config";
export default {
    components: {
        Submit,
        Edit
    },
    data() {
        return {
            paymentSetting: {}
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            defaultHead: "user/defaultHead",
            error: "error",
            loading: "loading"
        }),

        fs() {
            let userFields = [
                { heading: "Email", data: "email", type: this.id ? "readonly" : "text", validate: "required|email" },
                { heading: "Name", data: "name", type: "text" },
                { heading: "Company", data: "company", type: "text" },
                { heading: "Role", data: "role", type: "readonly" },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Active", data: "active", type: "boolean", action: true }
            ];

            let defaultPercent = (this.user.vendor && this.user.vendor.percent) || (this.paymentSetting.value && this.paymentSetting.value.amount_percent);

            let vendorFields = [
                { heading: "Email", data: "email", type: this.id ? "readonly" : "text", validate: "required|email" },
                { heading: "Name", data: "name", type: "readonly" },
                { heading: "Company", data: "company", type: "text" },
                { heading: "Role", data: "role", type: "readonly" },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Logo", data: "vendor.logo", type: "image", folder: "vendor" },
                { heading: "Business", data: "vendor.business", type: "text" },
                { heading: "Type", data: "vendor.vtype", type: "select", options: ["Rental Company", "Service Provider", "Ecommerce"] },
                { heading: "Vendor Name", data: "vendor.name", type: "text" },
                { heading: "Email", data: "vendor.email", type: "text" },
                { heading: "Phone", data: "vendor.phone", type: "text" },
                { heading: "Country", data: "vendor.country", type: "select", options: ["United States"] },
                { heading: "State", data: "vendor.state", type: "select", options: Object.keys(states).sort((a, b) => (a > b ? 1 : -1)) },
                { heading: "City", data: "vendor.city", type: "text" },
                { heading: "Address", data: "vendor.address", type: "text" },
                { heading: "Zip", data: "vendor.zip", type: "text" },
                { heading: "Welcome", data: "vendor.welcome", type: "textarea" },
                { heading: "App Fee %", data: "vendor.percent", type: "readonly", validate: "", default: defaultPercent },
                { heading: "Off Hour Addition Fee", data: "vendor.offHourAdditionFee", type: "number", validate: "min_value: 0" }
            ];

            if (this.paymentSetting.value && this.paymentSetting.value.use_tilled) {
                vendorFields.push({ heading: "EzTimePay Connect", data: "tilledConnectId", type: "readonly" });
            }

            if (this.paymentSetting.value && this.paymentSetting.value.use_stripe) {
                vendorFields.push({ heading: "Stripe Connect", data: "stripeConnectId", type: "readonly" });
            }

            vendorFields.push({ heading: "Active", data: "active", type: "boolean" });

            if (this.user.role == "vendor") {
                return vendorFields;
            }

            return userFields;
        }
    },
    watch: {},
    methods: {
        ...mapMutations({
            setUser: "user/setUser"
        }),
        ...mapActions({
            updateProfile: "user/updateProfile"
        })
    },
    async mounted() {},
    async created() {
        this.paymentSetting = await this.$store.dispatch("crud/get", {
            api: "settings/payment"
        });
    }
};
</script>
<style lang="less">
.profile-edit {
    .connect-ctrls {
        display: flex;
        align-items: center;
        .md-button:first-child {
            margin-left: 0;
        }
        button {
            height: 36px;
        }
    }
    .connect {
        margin-bottom: 10px;
        // margin-left: 0;
        margin-right: 10px;
    }
    .conncet-success {
        &.md-button:not([disabled]).md-raised:not(.md-icon-button) {
            background-color: #6bb100;
            color: white;
            height: 35px;
            margin-bottom: 10px;
            // margin-left: 0;
            margin-right: 10px;
        }
    }
    .md-card-actions {
        .md-button:first-child {
            margin-left: 9px;
        }
    }
}
</style>
